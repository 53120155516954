import React from 'react';
import axiosInstance from '../utils/axios';
import  useAuth  from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import useLocales from './useLocales';

export default function useRefresh() {
  const {logout} = useAuth()
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();

  const refresh = async () => {
    try {
      const payload = new FormData();

      payload.append('grant_type', 'refresh_token');

      const token = window.localStorage.getItem('refreshToken');

      if (!token) {
        throw new Error('Refresh token not found!');
      }

      payload.append('refresh_token', token);
      const { data } = await axiosInstance.post('/us-api/auth-detail/refreshToken', payload, {
        headers: {
          Authorization: 'Basic QVNQOlYxSTZyJWEpXjRqZkZHN2czNA==',
        },
      });
      return data;
    } catch (error) {
     
      const details = error?.response?.data?.error_description;
      if (details && details.includes('Invalid refresh token (expired)')) {
        // LOGOUT IF REFRESH TOKEN EXPIRED
       
        enqueueSnackbar(translate('sessiontimeout', 'Logged out due to session expired'), {
          variant: 'error',
        });
       
         logout()
      }
    }
  };

  return refresh;
}
