import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

import { PATH_AFTER_LOGIN } from '../config';

// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <ActivateAccount /> },
        { path: 'set-new-password', element: <SetNewPassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },

       

        {
          path: 'company',
          children: [
            { element: <CreateCompany />, index: true },
            // { path: 'posts', element: <BlogPosts /> },
            // { path: 'post/:title', element: <BlogPost /> },
            // { path: 'new-post', element: <BlogNewPost /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      // element: <MainLayout />,
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [
        { element: <HomePage />, index: true },
        { path: 'companies-list', element: <CompaniesList /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    {
      path: 'contractability',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'users', element: <UserList /> },
        { path: 'organizations', element: <CompaniesList /> },
        { path: 'create-organization', element: <CreateCompany /> },
        { path: 'contracts', element: <ContractList /> },
        { path: 'edit-organization/:id', element: <EditCompany /> },
      ],
    },
    {
      path: 'user',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'list', element: <UserList /> },
        { path: 'new', element: <UserCreate /> },
        { path: 'edit/:id', element: <UserCreate /> },
      ],
    },
    {
      path: 'group',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'list', element: <GroupList /> },
        { path: 'new', element: <GroupCreate /> },
        { path: 'edit/:id', element: <GroupCreate /> },
      ],
    },
    {
      path: 'organization',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'list', element: <CompaniesList /> },
        { path: 'new', element: <CreateCompany /> },
        { path: 'edit/:id', element: <EditCompany /> },
      ],
    },
    {
      path: 'contract',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'list', element: <ContractList /> },
        { path: 'deleted-list', element: <ContractDeletedList /> },
        { path: 'new', element: <ContractNew /> },
        { path: 'view/:id', element: <ContractDetails /> },
        { path: 'edit/:id', element: <ContractNew /> },
        

       ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const SetNewPassword = Loadable(lazy(() => import('../pages/auth/SetNewPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

//Company
const CreateCompany = Loadable(lazy(() => import('../pages/company/create-company')));
const EditCompany = Loadable(lazy(() => import('../pages/company/edit-company')));

//User
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/user/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/user/UserCreate')));

//group
const GroupList = Loadable(lazy(() => import('../pages/group/GroupList')));
const GroupCreate = Loadable(lazy(() => import('../pages/group/GroupCreate')));

// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Inside pages
const CompaniesList = Loadable(lazy(() => import('../pages/company/companies-list')));
const ContractList = Loadable(lazy(() => import('../pages/contracts/contract-list')));
const ContractDeletedList = Loadable(lazy(() => import('../pages/contracts/contract-deleted-list')));
const UserProfile = Loadable(lazy(() => import('../pages/user/user-profile')));
const ActivateAccount = Loadable(lazy(() => import('../pages/auth/activate-account')));

// contract
const ContractNew = Loadable(lazy(() => import('../pages/contracts/new-contract')));
const ContractDetails = Loadable(
  lazy(() => import('../pages/contract-details/contract-details-base'))
);


 