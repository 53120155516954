import { createSlice } from '@reduxjs/toolkit';

import { ContractFormData, PartyRecord } from '../../pages/contracts/contract-types';
import { dispatch } from '../store';
const initialState: ContractFormData = {
  title: '',
  contractOwner: '',
  tags: [],
  description: '',
  effectiveDate: null,
  endDate: null,
  terms:'',
  noticePeriod: '',
  noticePeriodType: '',
  parties: [],
  contractType: '',
  customField: '',
  customFieldData: {},
  ocrDates: [],
  ocrNoticePeriods: [],
  isSchemaEmpty: false,
  storageLocationUrl: '',
  location: [],
  untilFurtherNotice: false,
  fileOCRStatus: "",
  ocrCounterParties: [],
  typedTag: '',
  typedTagError: false,
  noEndDate: false,
  emailReminderDate:null,
  notificationToDecesion:null,
  ocrOrganizations:[],
  ocrContractTerms:[]
};

const slice = createSlice({
  name: 'contractf',
  initialState,
  reducers: {
    addParty(state, action) {
      const hasRecord = state.parties.find(
        (e: PartyRecord) => e.name === action.payload.name && e.type === action.payload.type
      );

      if (!hasRecord) {
        state.parties = [...state.parties, action.payload];
      }
    },
    removeParty(state, action) {
      state.parties = state.parties.filter(
        (party) => !(party.name === action.payload.name && party.type === action.payload.type)
      );
    },
    // updateState()
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

export function updateState(params: PartyRecord) {
  dispatch(actions.addParty(params));
  // return ()=>{
  // }
}

export function removePartyFn(params: PartyRecord) {
  return () => {
    dispatch(actions.removeParty(params));
  };
}
